export default function CommentIcon({ ...props }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      {...props}
    >
      <path
        d='M2.50016 10.667C1.92553 10.667 1.37443 10.4387 0.968098 10.0324C0.561769 9.62606 0.333496 9.07496 0.333496 8.50033V2.83366C0.333496 2.25902 0.561769 1.70792 0.968098 1.30159C1.37443 0.895265 1.92553 0.666992 2.50016 0.666992H11.5002C11.7847 0.666992 12.0664 0.723035 12.3293 0.83192C12.5922 0.940805 12.831 1.1004 13.0322 1.30159C13.2334 1.50279 13.393 1.74164 13.5019 2.00451C13.6108 2.26738 13.6668 2.54913 13.6668 2.83366V8.50033C13.6668 8.78486 13.6108 9.0666 13.5019 9.32947C13.393 9.59235 13.2334 9.8312 13.0322 10.0324C12.831 10.2336 12.5922 10.3932 12.3293 10.5021C12.0664 10.611 11.7847 10.667 11.5002 10.667H7.67483L4.3335 13.167C4.20969 13.2597 4.06253 13.316 3.9085 13.3298C3.75447 13.3436 3.59964 13.3143 3.46134 13.2451C3.32303 13.1759 3.2067 13.0696 3.12537 12.9381C3.04405 12.8065 3.00092 12.655 3.00083 12.5003V10.667H2.50016ZM7.34283 9.66699H11.5002C11.8096 9.66699 12.1063 9.54408 12.3251 9.32528C12.5439 9.10649 12.6668 8.80974 12.6668 8.50033V2.83366C12.6668 2.52424 12.5439 2.22749 12.3251 2.0087C12.1063 1.78991 11.8096 1.66699 11.5002 1.66699H2.50016C2.19074 1.66699 1.894 1.78991 1.6752 2.0087C1.45641 2.22749 1.3335 2.52424 1.3335 2.83366V8.50033C1.3335 9.14433 1.85616 9.66699 2.50016 9.66699H3.9995V12.167L7.34283 9.66699Z'
        fill='#7DA3F9'
      />
    </svg>
  );
}
