export default function CloseIcon({ ...props }) {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect width='32' height='32' rx='16' fill='#0E0E0E' fillOpacity='0.05' />
      <path
        d='M9.89705 10.0538L9.96967 9.96967C10.2359 9.7034 10.6526 9.6792 10.9462 9.89705L11.0303 9.96967L16 14.939L20.9697 9.96967C21.2359 9.7034 21.6526 9.6792 21.9462 9.89705L22.0303 9.96967C22.2966 10.2359 22.3208 10.6526 22.1029 10.9462L22.0303 11.0303L17.061 16L22.0303 20.9697C22.2966 21.2359 22.3208 21.6526 22.1029 21.9462L22.0303 22.0303C21.7641 22.2966 21.3474 22.3208 21.0538 22.1029L20.9697 22.0303L16 17.061L11.0303 22.0303C10.7641 22.2966 10.3474 22.3208 10.0538 22.1029L9.96967 22.0303C9.7034 21.7641 9.6792 21.3474 9.89705 21.0538L9.96967 20.9697L14.939 16L9.96967 11.0303C9.7034 10.7641 9.6792 10.3474 9.89705 10.0538L9.96967 9.96967L9.89705 10.0538Z'
        fill='#0E0E0E'
      />
    </svg>
  );
}
